import { useState, useRef } from 'react'
import { FilterOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import FilterForm from '@pig-casino/components/games/filter-form'
import { useMediaQuery, Drawer } from '@pig-frontend/uikit'
import styles from './index.module.scss'

export default function GameFilter() {
  const ctnRef = useRef()
  const isMD = useMediaQuery('md')
  const [isDropdownOpen, setOpenDropdown] = useState<boolean>(false)
  const [isDrawerOpen, setOpenDrawer] = useState<boolean>(false)

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  const handleOpenChange = (open: boolean) => {
    setOpenDropdown(open)
  }

  return (
    <div className={styles.container}>
      {isMD ? (
        <Dropdown
          className={styles.container}
          trigger={['click']}
          open={isDropdownOpen}
          onOpenChange={(open) => handleOpenChange(open)}
          getPopupContainer={(triggerNode) => triggerNode}
          placement="bottomRight"
          dropdownRender={() => (
            <div className={styles['filter-dropdown-container']}>
              <FilterForm onSubmit={() => handleOpenChange(false)} />
            </div>
          )}
        >
          <Button type="default" className={styles.btn}>
            <FilterOutlined />
          </Button>
        </Dropdown>
      ) : (
        <div ref={ctnRef}>
          <Drawer
            key="action-sheet"
            getContainer={ctnRef.current}
            open={isDrawerOpen}
            closable={false}
            onClose={handleCloseDrawer}
            placement="bottom"
            className={styles['drawer-container']}
          >
            <FilterForm onSubmit={handleCloseDrawer} />
          </Drawer>
          <Button
            type="default"
            className={styles.btn}
            onClick={handleOpenDrawer}
          >
            <FilterOutlined />
          </Button>
        </div>
      )}
    </div>
  )
}
