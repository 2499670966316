import { ItemType } from 'antd/es/menu/hooks/useItems'
import { SORT_TYPE } from '@pig-casino/types/Search.type'

export type SortGameItemTypes = ItemType & {
  key: SORT_TYPE
  label: string
  value: SORT_TYPE
}

export const menuConfigs: SortGameItemTypes[] = [
  {
    key: SORT_TYPE.GAME_NEW_OLD,
    label: 'เกมใหม่ - เก่า',
    value: SORT_TYPE.GAME_NEW_OLD,
  },
  {
    key: SORT_TYPE.GAME_OLD_NEW,
    label: 'เกมเก่า - ใหม่',
    value: SORT_TYPE.GAME_OLD_NEW,
  },
  {
    key: SORT_TYPE.RTP_HIGHT_LOW,
    label: 'RTP สูง - ต่ำ',
    value: SORT_TYPE.RTP_HIGHT_LOW,
  },
  {
    key: SORT_TYPE.RTP_LOW_HIGHT,
    label: 'RTP ต่ำ - สูง',
    value: SORT_TYPE.RTP_LOW_HIGHT,
  },
  {
    key: SORT_TYPE.VOLATILITY_HIGHT_LOW,
    label: 'ความผันผวนสูง - ต่ำ',
    value: SORT_TYPE.VOLATILITY_HIGHT_LOW,
  },
  {
    key: SORT_TYPE.VOLATILITY_LOW_HIGHT,
    label: 'ความผันผวนต่ำ - สูง',
    value: SORT_TYPE.VOLATILITY_LOW_HIGHT,
  },
]
