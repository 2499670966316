import { TabsProps } from 'antd'

export const tabsItems: TabsProps['items'] = [
  {
    key: 'baccarat',
    label: 'บาคาร่า',
    children: null,
  },
  {
    key: 'sicbo',
    label: 'ไฮโล',
    children: null,
  },
  {
    key: 'roulette',
    label: 'รูเล็ต',
    children: null,
  },
  {
    key: 'gameshow',
    label: 'เกมโชว์',
    children: null,
  },
]
