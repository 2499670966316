import { GameTypes } from '@pig-casino/types/Game.type'

export type FilterConfigType = {
  key: string
  label: string
  value: GameTypes
}

export const gameTypeConfig: FilterConfigType[] = [
  {
    key: 'Baccarat',
    label: 'บาคารา',
    value: 'Baccarat',
  },
  //   {
  //     key: 'poker',
  //     label: 'โป๊กเกอร์',
  //     value: '',
  //   },
  {
    key: 'DragonTiger',
    label: 'เสือมังกร',
    value: 'DragonTiger',
  },
  //   {
  //     key: 'craps',
  //     label: 'แคร็ปส์',
  //     value: 'craps',
  //   },
  {
    key: 'HighLow',
    label: 'ไฮโล',
    value: 'HighLow',
  },
  {
    key: 'GameShow',
    label: 'เกมส์โชว์',
    value: 'GameShow',
  },
  {
    key: 'Roulette',
    label: 'รูเล็ต',
    value: 'Roulette',
  },
  //   {
  //     key: 'firstperson',
  //     label: 'เฟิร์สเพอร์ซัน',
  //     value: '',
  //   },
  {
    key: 'Blackjack',
    label: 'แบล็คแจ็ค',
    value: 'Blackjack',
  },
]
