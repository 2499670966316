import { useEffect } from 'react'
// import { Button as UIButton, Modal } from '@pig-frontend/uikit'
// import { HomeFilled } from '@ant-design/icons'
// import { useRecoilValue, useResetRecoilState } from 'recoil'
// import { inboxStateCommon } from '@pig-common/recoils'
// import { GameCanvas } from '@pig-casino/components/game-canvas'
// import useBalance from '@pig-casino/hooks/useBalance'
// import useCoinWallet from '@pig-casino/hooks/useCoinWallet'
// import Loading from '@pig-casino/components/loading'
import { useRouter } from 'next/router'
// import styles from './index.module.scss'

interface GameModalProps {
  isOpen: boolean
  gameUrl: string
  isLoading?: boolean
  onClose?: () => void
}

// NOTE: Temporary remove game iframe and use redirect instead
// NOTE: Temporary remove bonus convert animation
export default function GameModal({ gameUrl }: GameModalProps) {
  const router = useRouter()
  //   const { refetch: fetchBalance } = useBalance({})
  //   const { refetch: fetchCoinWallet } = useCoinWallet({})
  //   const inboxBonus = useRecoilValue(inboxStateCommon.inboxBonusState)
  //   const resetInboxBonus = useResetRecoilState(inboxStateCommon.inboxBonusState)

  useEffect(() => {
    if (
      gameUrl &&
      !gameUrl?.includes('undefined') &&
      !gameUrl?.includes('null')
    ) {
      router.push(gameUrl)
    }
  }, [gameUrl])

  //   const onCloseGame = () => {
  //     fetchBalance()
  //     fetchCoinWallet()
  //     if (onClose) onClose()
  //   }

  //   useEffect(() => {
  //     const body = document.getElementsByTagName('body')?.[0]
  //     if (!isOpen) {
  //       body.style.overflowY = ''
  //     } else {
  //       body.style.overflowY = 'hidden'
  //     }
  //   }, [isOpen])

  return null
  //   return (
  //     <Modal
  //       open={isOpen}
  //       footer={null}
  //       zIndex={1002}
  //       closable={false}
  //       width="100%"
  //       className={styles.container}
  //       destroyOnClose
  //     >
  //       <div className={styles.panel}>
  //         <div className={styles.header}>
  //           <div style={{ display: 'flex' }}>
  //             <UIButton
  //               type="primary"
  //               className={styles.home}
  //               onClick={onCloseGame}
  //               icon={<HomeFilled className={styles.icon} />}
  //             ></UIButton>
  //           </div>
  //           {/* <GameCanvas
  //             activeBonus={inboxBonus.activeBonus}
  //             completedBonus={inboxBonus.completedBonus}
  //             resetBonusState={resetInboxBonus}
  //           /> */}
  //         </div>
  //         {isLoading ? (
  //           <div
  //             data-testid="game-modal-iframe-loading"
  //             className={styles['loading-container']}
  //           >
  //             <Loading />
  //           </div>
  //         ) : (
  //           <iframe data-testid="game-modal-iframe" src={gameUrl} />
  //         )}
  //       </div>
  //     </Modal>
  //   )
}
