import { CLOUD_STORAGE_CDN } from '@pig-common/models/buildtime-constant'

export function promotionImageAssetUrl(
  type: string,
  promoCode: string,
  fileName: string,
) {
  if (!promoCode || !fileName) {
    return ''
  } else {
    return `${CLOUD_STORAGE_CDN}/pigspin-assets/${type}/${promoCode}/${fileName}`
  }
}
