import { StaticImageData } from 'next/image'
import {
  lobbyGameCarouselLightningBacaratImage,
  lobbyGameCarouselCrazyTimeImage,
  lobbyGameCarouselGoldenImage,
  lobbyGameCarouselGonzosImage,
  lobbyGameCarouselLightingRouletteImage,
  lobbyGameCarouselRouletteImage,
  lobbyGameCarouselSuperSicboImage,
  lobbyGameCarouselSpeedBacaratImage,
} from '@pig-casino/constants/images'

interface ISlideGameProps {
  gameId: string
  image: StaticImageData | string
  name: string
  gameType: string
}

export const gameSlides: ISlideGameProps[] = [
  {
    gameId: 'LightningBac0001',
    image: lobbyGameCarouselLightningBacaratImage,
    name: 'Lightning Baccarat',
    gameType: 'baccarat',
  },
  {
    gameId: 'gwbaccarat000001',
    image: lobbyGameCarouselGoldenImage,
    name: 'Golden Wealth Baccarat',
    gameType: 'baccarat',
  },
  {
    gameId: 'leqhceumaq6qfoug',
    image: lobbyGameCarouselSpeedBacaratImage,
    name: 'Speed Baccarat A',
    gameType: 'baccarat',
  },
  {
    gameId: 'SuperSicBo000001',
    image: lobbyGameCarouselSuperSicboImage,
    name: 'Super Sic Bo',
    gameType: 'sicbo',
  },
  {
    gameId: 'LightningTable01',
    image: lobbyGameCarouselLightingRouletteImage,
    name: 'Lightning Roulette',
    gameType: 'roulette',
  },
  {
    gameId: 'vctlz20yfnmp1ylr',
    image: lobbyGameCarouselRouletteImage,
    name: 'Roulette',
    gameType: 'roulette',
  },
  {
    gameId: 'CrazyTime0000001',
    image: lobbyGameCarouselCrazyTimeImage,
    name: 'Crazy Time A',
    gameType: 'gameshow',
  },
  {
    gameId: 'GonzoTH000000001',
    image: lobbyGameCarouselGonzosImage,
    name: "Gonzo's Treasure Hunt",
    gameType: 'gameshow',
  },
]
