import { useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { StarOutlined, StarFilled } from '@ant-design/icons'
import { Button, Image } from '@pig-frontend/uikit'
import { IGameItem } from '@pig-casino/types/Game.type'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import useFavoriteGame from '@pig-casino/hooks/useFavoriteGame'
import styles from './index.module.scss'

interface GameCardProps {
  game: IGameItem
  order: number
  favIds: string[]
  onClickPlay: (game: IGameItem) => void
  isGameListFetchedAfterMount: boolean
}

export default function GameCard({
  game,
  order,
  favIds,
  onClickPlay,
  isGameListFetchedAfterMount = false,
}: GameCardProps) {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { mutate: updateFavorite } = useFavoriteGame()
  const [isDomMounted, setDomMounted] = useState<boolean>(false)
  const isFavorite = useMemo(() => {
    return favIds.indexOf(game.gameId) >= 0
  }, [favIds, game])

  useEffect(() => {
    setDomMounted(true)
  }, [])

  const togglePlayGame = () => {
    onClickPlay(game)
  }

  const toggleFavorite = (e, isFavorite) => {
    e.stopPropagation()
    updateFavorite({ game, isFavorite })
  }

  return (
    <div data-testid="game-card-container" className={styles.container}>
      <div data-testid="game-card-img" className={styles['image-container']}>
        {game?.isUnderConstruction && (
          <div className={styles['under-construction']}>
            <span>ปิดปรับปรุง</span>
          </div>
        )}
        <Image
          src={game?.iconUrl}
          width={86}
          height={86}
          alt={game?.gameNameTh || game?.gameName}
          className={cx(styles.image, {
            [styles['image-under-construction']]: game?.isUnderConstruction,
          })}
        />
      </div>

      <div className={styles.action}>
        <div className={styles.top}>
          <div className={styles['detail-wrapper']}>
            <div>
              <h5 data-testid="game-card-name" className={styles.title}>
                <span className={styles['list-style']}>{order}</span>
                {game?.gameNameTh || game?.gameName}
              </h5>
            </div>
          </div>
          {isDomMounted && isAuthenticated ? (
            <>
              {isFavorite ? (
                <StarFilled
                  className={cx(styles.icon, styles.favorite)}
                  onClick={(e) => toggleFavorite(e, false)}
                />
              ) : (
                <StarOutlined
                  className={styles.icon}
                  onClick={(e) => toggleFavorite(e, true)}
                />
              )}
            </>
          ) : (
            <StarFilled className={cx(styles.icon, styles.disabled)} />
          )}
        </div>
        <div className={styles.bottom}>
          <span className={styles.description}>{game?.gameProvider}</span>
          <Button
            type="primary"
            className={styles.btn}
            onClick={(e) => {
              e.stopPropagation()
              if (isGameListFetchedAfterMount) {
                togglePlayGame()
              }
            }}
            disabled={game?.isUnderConstruction}
          >
            เล่นเกม
          </Button>
        </div>
      </div>
    </div>
  )
}
