import { useQuery } from '@tanstack/react-query'
import { gamesApi } from '@pig-casino/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import { IGameBrands } from '@pig-casino/types/Game.type'
import { camelizeKeys } from 'libs/utils/src/camelize-keys'

interface ListGameBrandsHookType<SelectReturnType> {
  select?: (data: CommonResponse<IGameBrands[]>) => SelectReturnType
  enabled?: boolean
}

export default function useGameBrands<SelectReturnType = IGameBrands[]>({
  select,
  enabled,
}: ListGameBrandsHookType<SelectReturnType>) {
  return useQuery({
    queryKey: [gamesApi.key.getBrands],
    queryFn: gamesApi.getBrands,
    enabled,
    select: (data) =>
      select ? select(data) : (camelizeKeys(data?.data) as IGameBrands[]),
    refetchOnWindowFocus: true,
  })
}
