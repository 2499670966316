import { useCallback, useMemo, useState } from 'react'
import cx from 'classnames'
import { Button } from '@pig-frontend/uikit'
import {
  SoundFilled,
  GlobalOutlined,
  UserOutlined,
  StarOutlined,
  StarFilled,
} from '@ant-design/icons'
import { Image } from '@pig-frontend/uikit'
import { LobbyGameLiveIcon } from '@pig-casino/constants/images'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import useFavoriteGame from '@pig-casino/hooks/useFavoriteGame'
import { IGameItem } from '@pig-casino/types/Game.type'
import { gameType } from '@pig-casino/constants/game'
import usePlayGame from '@pig-casino/hooks/usePlayGame'
import useListGame from '@pig-casino/hooks/useListGame'
import styles from './index.module.scss'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

interface SliderCardProps {
  game: IGameItem
  onClickPlay: (game: IGameItem) => void
  favIds: string[]
  type: string
}

const GameTypeLabel = styled.div`
  position: absolute;
  top: 45%;
  left: 0%;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
  opacity: 0.15;
  text-align: center;
  z-index: 1;
`

const SliderCard = ({ game, onClickPlay, favIds, type }: SliderCardProps) => {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const [, { handleGoToGameDetail }] = usePlayGame()
  const { isFetchedAfterMount: isFetchedGameListAfterMount } = useListGame({})
  const [errorImage, setErrorImage] = useState<string[]>([])
  const ThumbnailImage = useCallback(() => {
    return (
      <Image
        fill
        src={
          errorImage.includes(game.thumbnailUrl) || isEmpty(game.thumbnailUrl)
            ? '/images/blank.png'
            : game.thumbnailUrl
        }
        alt={game?.gameName}
        onError={() => {
          setErrorImage((prev) => [...prev, game.thumbnailUrl])
        }}
      />
    )
  }, [errorImage])
  const isFavorite = useMemo(
    () => favIds.indexOf(game?.gameId) >= 0,
    [favIds, game],
  )
  const { mutate: updateFavorite } = useFavoriteGame()

  const handleClick = async (e) => {
    e.stopPropagation()
    updateFavorite({ game: game, isFavorite: !isFavorite })
  }

  const tsxCard = useMemo(() => {
    return (
      <>
        <div
          aria-hidden
          role="button"
          onClick={() => handleGoToGameDetail(game)}
        >
          {game?.isUnderConstruction && (
            <div
              className={cx(styles['under-construction'], {
                [styles.baccarat]: type === gameType.BACCARAT,
                [styles.gameshow]: type === gameType.GAMESHOW,
              })}
            >
              <span>ปิดปรับปรุง</span>
            </div>
          )}

          {isAuthenticated && (
            <div
              role="button"
              className={cx([styles.favorite, { [styles.active]: isFavorite }])}
              onClick={handleClick}
              onKeyDown={handleClick}
            >
              {isFavorite ? <StarFilled /> : <StarOutlined />}
            </div>
          )}
          <div className={styles.live}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 9 }}>
              {game?.isLive && <LobbyGameLiveIcon />}
              <div className={styles.sound}>
                <SoundFilled className={styles['sound-icon']} />
                {game?.language === 'th' ? (
                  <div className={styles.language}>ไทย</div>
                ) : (
                  <GlobalOutlined className={styles['global-icon']} />
                )}
              </div>
            </div>
          </div>
          {game?.players > 0 && (
            <div className={styles.people}>
              <div className={styles.panel}>
                <UserOutlined className={styles['people-icon']} />
                <div className={styles.text}>{game?.players}</div>
              </div>
            </div>
          )}

          <div
            className={cx(styles.card, {
              [styles.baccarat]: type === gameType.BACCARAT,
              [styles.gameshow]: type === gameType.GAMESHOW,
              [styles['image-under-construction']]: game?.isUnderConstruction,
            })}
          >
            <GameTypeLabel>{game.gameType}</GameTypeLabel>
            <ThumbnailImage />
          </div>
        </div>

        <div className={styles.footer}>
          <p className={styles['game-name']}>
            {game?.gameNameTh || game?.gameName}
          </p>
          <Button
            type="primary"
            className={styles.button}
            {...(isFetchedGameListAfterMount && {
              onClick: () => onClickPlay(game),
            })}
            disabled={game?.isUnderConstruction}
          >
            เล่นเกม
          </Button>
        </div>
      </>
    )
  }, [game, game.isUnderConstruction, favIds, errorImage])

  return tsxCard
}

export default SliderCard
