import {
  lobbyPaymentBankImage,
  //   lobbyPaymentCryptoImage,
  lobbyPaymentScanImage,
  lobbyPaymentTruewalletImage,
} from '@pig-casino/constants/images'
import { StaticImageData } from 'next/image'

type PaymentConfigType = {
  key: string
  alt: string
  image: StaticImageData | string
  title: string
  subTitle: string
}

export const paymentsConfig: PaymentConfigType[] = [
  {
    key: 'scan',
    alt: 'scan',
    image: lobbyPaymentScanImage,
    title: 'QR',
    subTitle: 'QR Payment',
  },
  {
    key: 'bank',
    alt: 'bank',
    image: lobbyPaymentBankImage,
    title: 'Bank Transfer',
    subTitle: 'Bank Transfer',
  },
  {
    key: 'truewallet',
    alt: 'truewallet',
    image: lobbyPaymentTruewalletImage,
    title: 'True Wallet',
    subTitle: 'True Wallet',
  },
  //   {
  //     key: 'crypto',
  //     alt: 'crypto',
  //     image: lobbyPaymentCryptoImage,
  //     title: 'Crypto',
  //     subTitle: 'Crypto',
  //   },
]
