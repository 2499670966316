export type FilterConfigType = {
  key: string
  label: string
  value: string
}

export const dealerConfig: FilterConfigType[] = [
  {
    key: 'en',
    label: 'อังกฤษ',
    value: 'en',
  },
  {
    key: 'th',
    label: 'คนไทย',
    value: 'th',
  },
  {
    key: 'other',
    label: 'ต่างชาติ',
    value: 'other',
  },
]
