import { useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { Input } from '@pig-frontend/uikit'
import { useRouter } from 'next/router'
import { updateUrlQuery } from '@pig-frontend/utils'
import { SearchParamType } from '@pig-casino/types/Search.type'
import styles from './index.module.scss'

export default function GameSearch() {
  const [form] = Form.useForm()
  const router = useRouter()
  const searchParam = router.query?.search
  const { pageType = 'all' } = router.query as SearchParamType

  useEffect(() => {
    form.setFieldValue('search', searchParam)
  }, [searchParam])

  const handleSubmit = ({ search }) => {
    updateUrlQuery(router, { search })
  }

  return (
    <Form form={form} onFinish={handleSubmit} className={styles.form}>
      <Form.Item name="search">
        <Input
          //   value={searchParam}
          prefix={<SearchOutlined className={styles['search-icon']} />}
          placeholder={pageType === 'providers' ? 'ค้นหาค่ายเกม' : 'ค้นหาเกม'}
        />
      </Form.Item>
    </Form>
  )
}
