import { useEffect, useState } from 'react'
import { Button, Divider, Radio, Checkbox } from '@pig-frontend/uikit'
import { Form } from 'antd'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import { dealerConfig } from './config/dealer'
import { gameTypeConfig } from './config/game-type'
import { updateUrlQuery } from '@pig-frontend/utils'
import { GameTypes } from '@pig-casino/types/Game.type'
import { SearchParamType } from '@pig-casino/types/Search.type'
import styles from './index.module.scss'

const qsKey: SearchParamType = {
  dealer: 'dealer',
  gameType: 'gameType',
}

interface FilterFormProps {
  onSubmit?: () => void
}

export default function FilterForm({ onSubmit }: FilterFormProps) {
  const router = useRouter()
  const gameTypeParam = router.query?.[qsKey.gameType]
  const dealerParam = router.query?.[qsKey.dealer]
  const [gameType, setGameType] = useState<GameTypes[] | undefined>()
  const [form] = Form.useForm()

  const handleSubmit = (values) => {
    values.gameType = gameType
    if (values.gameType) {
      updateUrlQuery(router, values)
    }
    onSubmit()
  }

  const handleClearValue = () => {
    form.resetFields()
    setGameType(undefined)
  }

  useEffect(() => {
    if (!isEmpty(gameTypeParam)) {
      form.setFieldValue(
        qsKey.gameType,
        typeof gameTypeParam === 'string' ? [gameTypeParam] : gameTypeParam,
      )
      form.setFieldValue(qsKey.dealer, dealerParam)
    }
  }, [gameTypeParam, dealerParam])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.title}>ตัวกรอง</span>
        <span
          role="button"
          onClick={handleClearValue}
          className={styles.clear}
          aria-hidden
        >
          ล้าง
        </span>
      </div>
      <Divider />
      <Form form={form} className={styles.form} onFinish={handleSubmit}>
        <div className={styles.body}>
          <div className={styles['category-container']}>
            <span className={styles.category}>ดีลเลอร์</span>
            <Form.Item name={qsKey.dealer} className={styles['form-control']}>
              <Radio.Group className={styles['dealer-group-container']}>
                {dealerConfig.map((cfg) => (
                  <Radio value={cfg.value} key={cfg.key}>
                    {cfg.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
          <div className={styles['category-container']}>
            <span className={styles.category}>ประเภทเกม</span>
            <Form.Item name={qsKey.gameType} className={styles['form-control']}>
              <Checkbox.Group
                className={styles['game-type-group-container']}
                onChange={(v: GameTypes[]) => setGameType(v)}
              >
                {gameTypeConfig.map((cfg) => (
                  <Checkbox value={cfg.value} key={cfg.key}>
                    {cfg.label}
                  </Checkbox>
                ))}
              </Checkbox.Group>
            </Form.Item>
          </div>
        </div>
        <div>
          <Divider />
          <div className={styles.footer}>
            <Button htmlType="submit" type="primary" block>
              ตกลง
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}
