import cx from 'classnames'
import { Image } from '@pig-frontend/uikit'
import { lobbyGameProviderHotImage } from '@pig-casino/constants/images'
import useGameBrands from '@pig-casino/hooks/useGameBrands'
import styles from './index.module.scss'

export interface IGameProviderProps {
  onClickProvider: (provider: string) => void
}

export default function GameProvider({ onClickProvider }: IGameProviderProps) {
  const { data: gameBrands = [], isFetchedAfterMount: isFetchedGameBrands } =
    useGameBrands({})

  return (
    <section className={styles.section}>
      <h4 className={styles.header}>ค่ายเกม</h4>
      <div className={styles.body}>
        {gameBrands.map(
          (item) =>
            item.isActive && (
              <div key={item?.uid} className={styles['card-wrapper']}>
                {item?.isHot && (
                  <Image
                    src={lobbyGameProviderHotImage}
                    alt={`${item?.name} - new`}
                    width={40}
                    height={16}
                    className={styles['new-icon']}
                  />
                )}

                <div
                  role="button"
                  aria-hidden
                  {...(isFetchedGameBrands && {
                    onClick: () => {
                      if (!item?.isComingSoon && !item?.isUnderConstruction) {
                        onClickProvider(item?.name)
                      }
                    },
                  })}
                  className={styles['card-box']}
                >
                  <Image
                    src={item?.logo}
                    width={260}
                    height={70}
                    className={cx(styles.image, {
                      [styles['image-under-construction']]:
                        item?.isComingSoon || item?.isUnderConstruction,
                    })}
                    alt={item?.name}
                  />
                </div>
                {item?.isComingSoon && (
                  <div className={styles['under-construction']}>
                    <span>พบกันเร็วๆนี้</span>
                  </div>
                )}
                {!item?.isComingSoon && item?.isUnderConstruction && (
                  <div className={styles['under-construction']}>
                    <span>ปิดปรับปรุง</span>
                  </div>
                )}
              </div>
            ),
        )}
      </div>
    </section>
  )
}
