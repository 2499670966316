import { useQuery } from '@tanstack/react-query'
import { campaignApi } from '@pig-casino/api'
import { CommonResponse } from '@pig-common/types/Api.type'
import { ICampaignData } from '@pig-casino/types/Campaign.type'

interface ListCampaignType {
  params?: campaignApi.IParamsProps
  select?: (data: CommonResponse<ICampaignData[]>) => ICampaignData[]
  enabled?: boolean
}

export default function useCampaign({
  params,
  select,
  enabled,
}: Partial<ListCampaignType>) {
  return useQuery({
    queryKey: [campaignApi.key.list],
    queryFn: () => campaignApi.list(params || {}),
    select: (data) => (select ? select(data) : data?.data || []),
  })
}
