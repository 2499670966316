import { useMutation } from '@tanstack/react-query'
import { authStateCommon } from '@pig-common/recoils'
import { gamesApi } from '@pig-casino/api'
import { IGameItem } from '@pig-casino/types/Game.type'
import { useRecoilValue } from 'recoil'
import { queryClient } from '@pig-casino/pages/_app.page'

interface IGameFavorite {
  game: IGameItem
  isFavorite: boolean
}

interface IOldData {
  data: IGameItem[]
}

const updateFavoriteList = (game: IGameItem, isFavorite, games = []) => {
  const list = []
  for (let i = 0; i < games.length; i += 1) {
    if (!isFavorite && game.gameId !== games[i].gameId) {
      list.push(games[i])
    } else if (isFavorite) {
      list.push(games[i])
    }
  }
  if (isFavorite) {
    list.push(game)
  }
  return list
}

export default function useFavoriteGame() {
  const { customerCode } = useRecoilValue(authStateCommon.authState)

  return useMutation({
    mutationFn: (params: IGameFavorite) => {
      const { isFavorite, game } = params
      return gamesApi.updateFavorite({
        customerCode,
        isFavorite,
        gameId: game.gameId,
      })
    },
    onSuccess: (response, params) => {
      const { game } = params
      const { isFavorite } = response.data
      queryClient.setQueryData(
        [gamesApi.key.list, gamesApi.key.favorite],
        (oldData: IOldData) => ({
          ...oldData,
          data: updateFavoriteList(game, isFavorite, oldData?.data),
        }),
      )
    },
  })
}
