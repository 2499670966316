import { Swiper, SwiperSlide } from 'swiper/react'
import SliderCard from './card'
import { pageLinkCommon } from '@pig-common/constants/page-name'
import { IChampionItem } from '@pig-casino/types/Champion.type'
import styles from './index.module.scss'
import games from '@pig-casino/components/games/list/games'
import { SectionHeader, HeaderLink } from '@pig-common/components/SectionHeader'

export interface IChampionSliderProps {
  champions?: IChampionItem[]
}

export default function ChampionSlider({
  champions = [],
}: IChampionSliderProps) {
  return (
    <div className="container">
      <SectionHeader
        sectionId="champion"
        title="แชมป์เปี้ยน"
        content={
          <HeaderLink
            sectionId="champion"
            text={'ทั้งหมด'}
            number={games.length}
            linkUrl={pageLinkCommon.champion}
          />
        }
      />
      <div className={styles.container}>
        <Swiper spaceBetween={8} slidesPerView={1} width={330}>
          {champions.map((champion) => (
            <SwiperSlide key={champion?.uid}>
              <SliderCard champion={champion} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}
