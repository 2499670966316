import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import { Button } from '@pig-frontend/uikit'
import { useRouter } from 'next/router'
import { StarFilled, StarOutlined } from '@ant-design/icons'
import { updateUrlQuery } from '@pig-frontend/utils'
import styles from './index.module.scss'

type favoriteTypes = 'true' | undefined

export default function GameFavorite() {
  const router = useRouter()
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const isFavorite = router.query?.favorite as favoriteTypes
  const pageType = router.query?.slug as string
  const [isDomMounted, setMountedDom] = useState(false)
  const isDisableFavorite =
    isDomMounted && (pageType === 'favorite' || !isAuthenticated)

  const toggleFavorite = () => {
    const favorite = isFavorite === 'true' ? undefined : 'true'
    updateUrlQuery(router, { favorite })
  }

  useEffect(() => {
    setMountedDom(true)
  }, [])

  return (
    <Button
      type="default"
      className={cx(
        styles.container,
        { [styles.disabled]: isDisableFavorite },
        { [styles['favorite-opacity']]: pageType === 'favorite' },
      )}
      onClick={toggleFavorite}
      disabled={isDisableFavorite}
    >
      {isFavorite === 'true' ? (
        <StarFilled className={cx(styles.icon, styles.filled)} />
      ) : (
        <StarOutlined className={styles.icon} />
      )}
    </Button>
  )
}
