import { DownOutlined } from '@ant-design/icons'
import { Dropdown, MenuProps } from 'antd'
import { useRouter } from 'next/router'
import { menuConfigs as items, SortGameItemTypes } from './config'
import styles from './index.module.scss'

export default function GameSort() {
  const router = useRouter()
  const defaultSortParam = items[0].value
  const sortParam = (router.query.sort || defaultSortParam) as string
  const sortLabel = items.find(
    (v: SortGameItemTypes) => v.key === sortParam,
  ).label

  const onClick: MenuProps['onClick'] = ({ key }) => {
    router.push({ query: { ...router.query, sort: key } })
  }

  return (
    <Dropdown
      menu={{ items, onClick, selectedKeys: [sortParam] }}
      className={styles.container}
      trigger={['click']}
      getPopupContainer={(triggerNode) => triggerNode}
      placement="bottomRight"
      dropdownRender={(originNode) => (
        <div className={styles['popup-container']}>{originNode}</div>
      )}
    >
      <div role="menuitem">
        <span>{sortLabel}</span>
        <DownOutlined className={styles.icon} />
      </div>
    </Dropdown>
  )
}
