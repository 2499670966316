import { StaticImageData } from 'next/image'
import {
  lobbyPartner918KissImage,
  lobbyPartnerBetflikImage,
  //   lobbyPartnerScrSlotsImage,
  lobbyPartnerHungryPussyImage,
  lobbyPartnerLottoImage,
  lobbyPartnerPigspinImage,
  lobbyPartnerPigspinWhiteImage,
  lobbyPartnerLottoWhiteImage,
  lobbyPartnerBetflikWhiteImage,
  lobbyPartnerHungryPussyWhiteImage,
  lobbyPartner918KissWhiteImage,
} from '@pig-casino/constants/images'

export type PartnerKeysType =
  | 'pigspin'
  | 'lotto'
  | 'betflik'
  | 'hungrypussy'
  | 'csrslots'
  | '918pussy'

type PartnerConfigType = {
  key: PartnerKeysType
  image?: StaticImageData | string
  whiteImage?: StaticImageData | string
  link?: string
}

export const partnersConfig: PartnerConfigType[] = [
  {
    key: 'pigspin',
    image: lobbyPartnerPigspinImage,
    whiteImage: lobbyPartnerPigspinWhiteImage,
    link: 'https://pigspin.net',
  },
  {
    key: 'lotto',
    image: lobbyPartnerLottoImage,
    whiteImage: lobbyPartnerLottoWhiteImage,
    link: 'https://116lotto.app',
  },
  {
    key: 'betflik',
    image: lobbyPartnerBetflikImage,
    whiteImage: lobbyPartnerBetflikWhiteImage,
    link: 'https://betflik168.com',
  },
  {
    key: 'hungrypussy',
    image: lobbyPartnerHungryPussyImage,
    whiteImage: lobbyPartnerHungryPussyWhiteImage,
    link: 'https://hungrypussy888.com',
  },
  //   {
  //     key: 'csrslots',
  //     image: lobbyPartnerScrSlotsImage,
  //     link: 'https://scrslots918.com/',
  //   },
  {
    key: '918pussy',
    image: lobbyPartner918KissImage,
    whiteImage: lobbyPartner918KissWhiteImage,
    link: 'https://918kissme.vip',
  },
]
