import { useEffect } from 'react'
import { useRouter } from 'next/router'
import useListGame from '@pig-casino/hooks/useListGame'
import { SearchParamType } from '@pig-casino/types/Search.type'
import { Divider } from 'antd'
import { EVO_GAME_URL } from '@pig-casino/constants/env'
import { GameModal } from '@pig-casino/components/lobby'
import { gamesApi } from '@pig-casino/api'
import { IGameItem } from '@pig-casino/types/Game.type'
import { GameCard } from '@pig-casino/components/games'
import isEmpty from 'lodash/isEmpty'
import Loading from '@pig-casino/components/loading'
import useGameEntry from '@pig-casino/hooks/useGameEntry'
import usePlayGame from '@pig-casino/hooks/usePlayGame'
import { useRecoilValue } from 'recoil'
import { authStateCommon } from '@pig-common/recoils'
import styles from './index.module.scss'

interface IGameListProps {
  isLoading?: boolean
}

export default function GamesList({ isLoading = false }: IGameListProps) {
  const router = useRouter()
  const params = router.query as SearchParamType
  const pageType = router.query?.slug as string
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { data: games, isFetchedAfterMount: isGameListFetchedAfterMount } =
    useListGame({
      ...(pageType !== 'all' && { key: [gamesApi.key?.[pageType]] }),
      enabled: false,
      params,
    })
  const {
    data: gameEntry,
    mutate: getGameEntry,
    reset: resetGameEntry,
    isIdle: isGameEntryIdle,
    isLoading: isGameEntryLoading,
  } = useGameEntry()
  const [, { handleGoToGameDetail }] = usePlayGame()
  const { data: favIds = [], refetch: getFavorites } = useListGame<string[]>({
    params: {
      favorite: true,
    },
    enabled: isAuthenticated,
    select: (data) => data?.map((item) => item?.gameId),
    key: [gamesApi.key.favorite],
  })

  useEffect(() => {
    if (params?.favorite) {
      getFavorites()
    }
  }, [params.favorite])

  const onClickPlay = ({ tableId }: IGameItem) => {
    getGameEntry({ tableId })
  }

  return (
    <>
      {isLoading ? (
        <div className={styles['loading-container']}>
          <Loading />
        </div>
      ) : (
        <>
          <GameModal
            isOpen={!isGameEntryIdle}
            onClose={resetGameEntry}
            gameUrl={`${EVO_GAME_URL}${gameEntry?.data?.entry}`}
            isLoading={isGameEntryLoading}
          />
          {isEmpty(games) ? (
            <div
              data-testid="game-list-empty"
              className={styles['empty-container']}
            >
              <span>ไม่พบรายการ</span>
            </div>
          ) : (
            <div
              data-testid="games-list-container"
              className={styles['list-container']}
            >
              {games
                ?.filter((game) => !!game.gameId && !!game.gameName)
                .map((game, index) => (
                  <div
                    key={`${game?.gameId} - ${game?.gameName} - ${game?.tableId} - ${game?.gameProvider}`}
                    role="button"
                    aria-hidden
                    onClick={() => handleGoToGameDetail(game)}
                  >
                    <GameCard
                      isGameListFetchedAfterMount={isGameListFetchedAfterMount}
                      order={index + 1}
                      game={game}
                      favIds={favIds as string[]}
                      onClickPlay={onClickPlay}
                    />
                    <Divider className={styles.divider} />
                  </div>
                ))}
            </div>
          )}
        </>
      )}
    </>
  )
}
