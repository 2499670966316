import { pageLink } from '@pig-casino/constants/page-name'
import { useRouter } from 'next/router'
import { IGameItem } from '@pig-casino/types/Game.type'

export default function usePlayGame() {
  const router = useRouter()

  const handleGoToGameDetail = (game: IGameItem) => {
    router.push(`${pageLink.game}/${game?.gameName?.replace(/ /g, '-')}`)
  }

  return [null, { handleGoToGameDetail }]
}
