import { useEffect, useState } from 'react'
import cx from 'classnames'
import { useRouter } from 'next/router'
import { GameModal } from '@pig-casino/components/lobby'
import { IGameBrands } from '@pig-casino/types/Game.type'
import { SearchParamType } from '@pig-casino/types/Search.type'
import isEmpty from 'lodash/isEmpty'
import { lobbyGameProviderHotImage } from '@pig-casino/constants/images'
import useGameBrands from '@pig-casino/hooks/useGameBrands'
import useGameLobby from '@pig-casino/hooks/useGameLobby'
import { Image, useMediaQuery } from '@pig-frontend/uikit'
import styles from './index.module.scss'

export default function GameProvidersList() {
  const router = useRouter()
  const params = router.query as SearchParamType
  const pageType = router.query?.slug as string
  const isMD = useMediaQuery('md')
  const [activeTab, setActiveTab] = useState<typeof pageType>(pageType)
  const { data: gameBrands = [], isFetchedAfterMount: isFetchedGameBrands } =
    useGameBrands({})
  const [filteredGameProviders, setFilteredGameProviders] =
    useState<IGameBrands[]>(gameBrands)
  const {
    data: gameLobby,
    mutate: getGameLobby,
    reset: resetGameLobby,
    isIdle: isGameLobbyIdle,
    isLoading: isGameLobbyLoading,
  } = useGameLobby()

  const onClickProvider = (provider) => {
    getGameLobby({ provider, isMobile: !isMD })
  }

  useEffect(() => {
    // Update active tab follow by param pageType
    if (pageType !== activeTab) {
      setActiveTab(pageType)
    }
  }, [pageType])

  useEffect(() => {
    // Manual filter game providers
    if (pageType === 'providers') {
      if (!isEmpty(params?.search)) {
        const filtered = gameBrands?.filter((gameProvider) => {
          return gameProvider?.name
            ?.toLowerCase()
            ?.includes(params?.search?.toLowerCase())
        })
        setFilteredGameProviders(filtered)
      } else {
        setFilteredGameProviders(gameBrands)
      }
    }
  }, [params?.search, gameBrands, pageType])

  return (
    <div className={styles['list-providers-container']}>
      <GameModal
        isOpen={!isGameLobbyIdle}
        isLoading={isGameLobbyLoading}
        onClose={resetGameLobby}
        gameUrl={gameLobby?.data?.url}
      />
      {filteredGameProviders.map(
        (gameProvider) =>
          gameProvider?.isActive && (
            <div key={gameProvider?.uid} className={styles['card-wrapper']}>
              {gameProvider.isHot && (
                <Image
                  src={lobbyGameProviderHotImage}
                  alt={`${gameProvider.name} - new`}
                  width={40}
                  height={16}
                  className={styles['new-icon']}
                />
              )}
              <div
                role="button"
                aria-hidden
                {...(isFetchedGameBrands && {
                  onClick: () => {
                    if (
                      !gameProvider?.isComingSoon &&
                      !gameProvider?.isUnderConstruction
                    ) {
                      onClickProvider(gameProvider?.name)
                    }
                  },
                })}
                className={styles['card-box']}
              >
                <Image
                  src={gameProvider?.logo}
                  width={511}
                  height={165}
                  alt={gameProvider?.name}
                  placeholder="empty"
                  className={cx(styles.image, {
                    [styles['image-under-construction']]:
                      gameProvider?.isComingSoon ||
                      gameProvider?.isUnderConstruction,
                  })}
                />
              </div>
              {gameProvider?.isComingSoon && (
                <div className={styles['under-construction']}>
                  <span>พบกันเร็วๆนี้</span>
                </div>
              )}
              {!gameProvider.isComingSoon &&
                gameProvider?.isUnderConstruction && (
                  <div className={styles['under-construction']}>
                    <span>ปิดปรับปรุง</span>
                  </div>
                )}
            </div>
          ),
      )}
    </div>
  )
}
