import { Swiper, SwiperSlide } from 'swiper/react'
import SliderCard from './card'
import { IGameItem } from '@pig-casino/types/Game.type'
import { HeaderLink, SectionHeader } from '@pig-common/components/SectionHeader'
import styles from './index.module.scss'
import Spacer from '@pig-common/components/spacer'

export interface IGameSliderProps {
  sectionId: string
  games?: IGameItem[]
  title: string
  favIds: string[]
  link?: string
  onClickPlay: (game: IGameItem) => void
  type?: string
}

export default function GameSlider({
  sectionId,
  games = [],
  title,
  favIds,
  onClickPlay,
  link,
  type,
}: IGameSliderProps) {
  return (
    <div className="container">
      <SectionHeader
        sectionId={sectionId}
        title={`${title}`}
        content={
          <HeaderLink
            sectionId={sectionId}
            text={'ทั้งหมด'}
            number={games.length}
            linkUrl={link}
          />
        }
      />
      <div className={styles.container}>
        <Swiper
          spaceBetween={8}
          slidesPerView={1}
          width={272}
          breakpoints={{
            768: {
              slidesPerView: 1,
              width: 340,
            },
          }}
        >
          {games.map((game) => (
            <SwiperSlide key={game?.gameId}>
              <SliderCard
                game={game}
                favIds={favIds}
                onClickPlay={onClickPlay}
                type={type}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Spacer />
    </div>
  )
}
