import { StaticImageData } from 'next/image'
import {
  lobbyReward1Image,
  lobbyReward2Image,
  lobbyReward3Image,
} from '@pig-casino/constants/images'

type RewardsConfigType = {
  key: string
  image: StaticImageData | string
  text: string
}

export const rewardsConfig: RewardsConfigType[] = [
  {
    key: 'reward1',
    image: lobbyReward1Image,
    text: 'Reward1',
  },
  {
    key: 'reward2',
    image: lobbyReward2Image,
    text: 'Reward2',
  },
  {
    key: 'reward3',
    image: lobbyReward3Image,
    text: 'Reward3',
  },
]
