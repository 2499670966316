import { FilterTypes } from '@pig-casino/types/Game.type'

export enum SORT_TYPE {
  GAME_NEW_OLD = 'game-new-to-old',
  GAME_OLD_NEW = 'game-old-to-new',
  RTP_HIGHT_LOW = 'rtp-rate-hight-to-low',
  RTP_LOW_HIGHT = 'rtp-rate-low-to-hight',
  RTPA_HIGHT_LOW = 'rtp-hight-to-low',
  RTPA_LOW_HIGHT = 'rtp-low-to-hight',
  VOLATILITY_HIGHT_LOW = 'volatility-hight-to-low',
  VOLATILITY_LOW_HIGHT = 'volatility-low-to-hight',
}

export type SearchParamType = {
  default?: undefined
  search?: string
  dealer?: string
  filterType?: FilterTypes
  gameType?: string
  limit?: number
  favorite?: boolean
  pageType?: 'all' | 'favorite' | 'popular' | 'recent' | 'providers'
}
