import { Image } from '@pig-frontend/uikit'
import { rewardsConfig } from './config'
import styles from './index.module.scss'
import { SectionHeader } from '@pig-common/components/SectionHeader'

export default function AwardSection() {
  return (
    <div className={styles.container}>
      <SectionHeader sectionId="reward" title="รางวัล" />
      {rewardsConfig.map(({ key, image, text }) => (
        <div key={key} className={styles['item-container']}>
          <div className={styles.item}>
            <Image src={image} alt={key} className={styles.img} />
          </div>
          <span className={styles.text}>{text}</span>
        </div>
      ))}
    </div>
  )
}
